import http from "./utils/http-common";
import {ANACOLOR_TOKEN, ANACOLOR_USER} from "../utils/constants";
import QueryService from "./utils/query.service";
import {User} from "../models/user";

const LOGIN_ENDPOINT = "/profile";
const PROFILE_ENDPOINT = "/profile";

class AuthService {

  login(username, password) {
    const token = btoa(`${username}:${password}`);
    return http
      .get(LOGIN_ENDPOINT, {
        headers: {
          'Authorization': `Basic ${token}`
        }
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem(ANACOLOR_USER, JSON.stringify(response.data));
        }
        localStorage.setItem(ANACOLOR_TOKEN, token);
        http.defaults.headers['Authorization'] = `Basic ${token}`;
        return response.data;
      });
  }

  getProfile() {
    return QueryService.getQuery<User>(PROFILE_ENDPOINT);
  }

  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
