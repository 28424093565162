import {
    ADD_OPPORTUNITY_FAIL,
    ADD_OPPORTUNITY_SUCCESS, DELETE_OPPORTUNITY_FAIL, DELETE_OPPORTUNITY_SUCCESS, GET_OPPORTUNITIES_FAIL,
    GET_OPPORTUNITIES_SUCCESS, UPDATE_OPPORTUNITY_FAIL, UPDATE_OPPORTUNITY_SUCCESS
} from "../actions/types";
import { Opportunity } from "../models/opportunity";

interface OpportunitiesState {
    opportunities: Opportunity[]
    error: boolean,
    succeed: boolean,
}

const initialState:OpportunitiesState = {
    opportunities: [],
    error: false,
    succeed: false
};

export default function opportunityReducer (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                error: false,
                succeed: false,
                opportunities: payload.opportunities,
            };
        case ADD_OPPORTUNITY_SUCCESS:
            return {
                ...state,
                error: false,
                succeed: true,
            };
        case UPDATE_OPPORTUNITY_SUCCESS:
            return {
                ...state,
                error: false,
                succeed: true,
            };
        case DELETE_OPPORTUNITY_SUCCESS:
            return {
                ...state,
                opportunities: state.opportunities.filter(d => d.id !== payload.id)
            };
        case GET_OPPORTUNITIES_FAIL:
        case ADD_OPPORTUNITY_FAIL:
        case UPDATE_OPPORTUNITY_FAIL:
        case DELETE_OPPORTUNITY_FAIL:
            return {
                ...state,
                error: true,
                succeed: false,
                errorMsg: payload,
            };
        default:
            return state;
    }
}