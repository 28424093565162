import {
  ADD_COMMUNICATIONS_FAIL,
  ADD_COMMUNICATIONS_SUCCESS,
  DELETE_COMMUNICATIONS_FAIL,
  DELETE_COMMUNICATIONS_SUCCESS,
  GET_COMMUNICATIONS_FAIL,
  GET_COMMUNICATIONS_SUCCESS,
  UPDATE_COMMUNICATIONS_FAIL,
  UPDATE_COMMUNICATIONS_SUCCESS
} from "../actions/types";
import {createSelector} from "reselect";
import {Communication} from "../models/communication";

interface CommuncationState {
  communications: Communication[]
  error: boolean,
  succeed: boolean,

}

const initialState:CommuncationState = {
  communications: [],
  error: false,
  succeed: false
};


export default function communicationReducer (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMMUNICATIONS_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: false,
        communications: payload.communications,
      };
    case ADD_COMMUNICATIONS_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: true,
      };
    case UPDATE_COMMUNICATIONS_SUCCESS:
      return {
        ...state,
        updatedCommunication: payload.updatedCommunication,
        error: false,
        succeed: true,
      };
    case DELETE_COMMUNICATIONS_SUCCESS:
      return {
        ...state,
        communications: state.communications.filter(d => d.id !== payload.id)
      };
    case GET_COMMUNICATIONS_FAIL:
    case ADD_COMMUNICATIONS_FAIL:
    case UPDATE_COMMUNICATIONS_FAIL:
    case DELETE_COMMUNICATIONS_FAIL:
      return {
        ...state,
        error: true,
        succeed: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
}