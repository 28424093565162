export type Permission = string;

export enum Permissions {
    USERS_READ = "users_read",
    USERS_WRITE = "users_write",
    BUSINESSES_READ = "businesses_read",
    BUSINESSES_WRITE = "businesses_write",
    CONTACTS_READ = "contacts_read",
    CONTACTS_WRITE = "contacts_write",
    QUOTES_READ = "quotes_read",
    QUOTES_WRITE = "quotes_write",
    COMMUNICATIONS_READ = "communications_read",
    COMMUNICATIONS_WRITE = "communications_write",
    EVENTS_READ = "events_read",
    EVENTS_WRITE = "events_write",
    PRODUCTS_READ = "products_read",
    PRODUCTS_WRITE = "products_write",
    SUPPLIERS_READ = "suppliers_read",
    SUPPLIERS_WRITE = "suppliers_write",
    INVENTORIES_READ = "inventories_read",
    INVENTORIES_WRITE = "inventories_write",
    RECIPES_READ = "recipes_read",
    RECIPES_WRITE = "recipes_write",
    OPPORTUNITIES_READ = "opportunities_read",
    OPPORTUNITIES_WRITE = "opportunities_write",
    PRODUCTION_READ = "production_read",
    PRODUCTION_WRITE = "production_write",
    DASHBOARD_READ = "dashboard_read",
    DASHBOARD_WRITE = "dashboard_write",
    NOONE = "no_one",
}
