import axios from "axios";
import {API_BASE_URL} from "../../utils/constants";
import { apiError, apiSuccess, resetApiError, resetApiSuccess } from "../../actions/api.action";
import store from "../../store";

// const username = 'maximeb';
// const password = 'maxime2021';
// const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

const headers = {
    "Content-type": "application/json"
};

const token = localStorage.getItem('anacolor.token');
if(token != null) {
    headers['Authorization'] = `Basic ${token}`;
}


const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers
});

axiosInstance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
})

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    const method = response.config.method;
    const METHODS_FOR_SAVING = ["POST", "PUT", "PATCH", "DELETE"]
    if (METHODS_FOR_SAVING.includes(method.toUpperCase())) {
        store.dispatch(apiSuccess());
        setTimeout(function(){
            store.dispatch(resetApiSuccess());
        }, 3000);
    }
    return response;
}, function (error) {
    store.dispatch(apiError(error));
    // setTimeout(function(){
    //     store.dispatch(resetApiError());
    // }, 100000);
    return Promise.reject(error);
});

export default axiosInstance;
