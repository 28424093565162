import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetApiError, resetApiSuccess } from "../../actions/api.action";
import { Button, Grid } from "@mui/material";

interface StateProps {
    apiError : {
        apiError
    },
    apiSuccess: {
        apiSuccess
    }
}

interface AlertSettings {
    background: string,
    color: string,
    closeButton: boolean,
    text: string,
}

export const ApiAlert: FC = () => {

    const {apiError} = useSelector((state: StateProps) => state.apiError);
    const {apiSuccess} = useSelector((state: StateProps) => state.apiSuccess);
    const dispatch = useDispatch();

    const closeErrorAlert = () => {
        dispatch(resetApiError());
    }

    const closeSuccessAlert = () => {
        dispatch(resetApiSuccess());
    }

    return(
        <>
            {apiError &&
                <div style={{position: "absolute", width: "50%", marginLeft: "25%",background: "palevioletred", color: "white", padding: "1em", top: "5em", borderRadius: "5px"}}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            {apiError.message} ({apiError.status})
                        </Grid>
                        <Grid item>
                            <Button onClick={closeErrorAlert} style={{color: "white"}}>x</Button>
                        </Grid>
                    </Grid>
                </div>
            }
            {apiSuccess &&
                <div style={{position: "absolute", width: "50%", marginLeft: "25%", background: "aquamarine", color: "black", padding: "1em", top: "5em", borderRadius: "5px"}}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                            Modifications sauvegardées.
                        </Grid>
                        <Grid item>
                            <Button onClick={closeSuccessAlert} style={{color: "black"}}>x</Button>
                        </Grid>
                    </Grid>
                </div>
            }
        </>
    )

}