import {
    GET_BUSINESS_TYPES_FAIL,
    GET_BUSINESS_TYPES_SUCCESS,
    GET_COMMUNICATION_TYPES_FAIL,
    GET_COMMUNICATION_TYPES_SUCCESS,
    GET_COUNTRIES_FAIL,
    GET_COUNTRIES_SUCCESS, GET_LANGUAGES_FAIL,
    GET_LANGUAGES_SUCCESS,
    GET_PROVINCES_FAIL,
    GET_PROVINCES_SUCCESS
} from "../actions/types";
import {Country} from "../models/country";
import {Province} from "../models/province";
import {Lang} from "../models/lang";

interface CommonsState {
    countries: Country[],
    provinces: Province[],
    languages: Lang[]
}

const initialState = {
    countries: [],
    provinces: [],
    languages: []
};

export default function commonsReducer (state: CommonsState = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: payload.countries
            };
        case GET_COUNTRIES_FAIL:
            return {
                ...state,
            };
        case GET_PROVINCES_SUCCESS:
            return {
                ...state,
                provinces: payload.provinces
            };
        case GET_PROVINCES_FAIL:
            return {
                ...state,
            };
        case GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                languages: payload.languages
            };
        case GET_LANGUAGES_FAIL:{
            return {
                ...state,
            };
        }
        case GET_COMMUNICATION_TYPES_SUCCESS:
            return {
                ...state,
                communicationTypes: payload.communicationTypes
            };
        case GET_COMMUNICATION_TYPES_FAIL:
            return {
                ...state,
            };
        case GET_BUSINESS_TYPES_SUCCESS:
            return {
                ...state,
                businessTypes: payload.businessTypes
            };
        case GET_BUSINESS_TYPES_FAIL:
            return {
                ...state,
            };
        default:
            return state;
    }
}