import http from "./http-common";

class QueryService {

    getQuery<T>(endpoint: string, params?) {
        return http
            .get<Array<T>>(endpoint, params)
            .then((response) => {
                return response.data;
            });
    }

    getQueryById<T>(endpoint: string, params?) {
        return http
            .get<T>(endpoint, params)
            .then((response) => {
                return response.data;
            });
    }

    postQuery<T>(endpoint: string, payload: T, params?) {
        return http
            .post<T>(endpoint, payload, params)
            .then((response) => {
                return response.data;
            });
    }

    putQuery<T>(endpoint: string, payload: T, params?) {
        console.log('putQuery', endpoint);
        return http
            .put<T>(endpoint, payload, params)
            .then((response) => {
                return response.data;
            });
    }

    deleteQuery(endpoint: string, params = undefined){
        return http
            .delete(endpoint, params)
            .then(() => {
                return true;
            });
    }

}

export default new QueryService();
