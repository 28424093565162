import {
    ADD_PRODUCT_SUCCESS,
    ADD_RECIPE_FAIL,
    ADD_RECIPE_SUCCESS, ADD_UPDATE_RECIPE_DETAILS_FAIL, ADD_UPDATE_RECIPE_DETAILS_SUCCESS,
    DELETE_RECIPE_FAIL,
    DELETE_RECIPE_SUCCESS,
    GET_PAINT_TYPES_FAIL,
    GET_PAINT_TYPES_SUCCESS,
    GET_PRODUCT_BY_ID_FAIL,
    GET_PRODUCT_BY_ID_SUCCESS,
    GET_PRODUCT_OPTIONS_SUCCESS,
    GET_PRODUCT_TYPES_FAIL,
    GET_PRODUCT_TYPES_SUCCESS,
    GET_PRODUCTS_CATEGORIES_FAIL,
    GET_PRODUCTS_CATEGORIES_SUCCESS,
    GET_PAINT_CATEGORIES_FAIL,
    GET_PAINT_CATEGORIES_SUCCESS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_OPTIONS_FAIL,
    GET_PRODUCTS_SUCCESS, GET_RECIPE_BY_ID_FAIL,
    GET_RECIPE_BY_ID_SUCCESS,
    GET_RECIPES_FAIL,
    GET_RECIPES_SUCCESS,
    UPDATE_PRODUCT_SUCCESS, PRODUCTS_LOADING,
} from "../actions/types";
import {Recipe} from "../models/recipe";
import {Product} from "../models/products/product";

interface RecipesState {
    recipes: Recipe[],
    recipe: Recipe
}

interface ProductState {
    products: Product[],
    recipes: RecipesState,
    loading: boolean
}

const initialState = {products: [], recipes: {recipes: [], recipe: {} as Recipe}, loading: false };

export default function productReducer (state: ProductState = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case PRODUCTS_LOADING: {
            return {
                ...state,
                loading: true
            }
        }
        case GET_PRODUCTS_SUCCESS:
            return {
                ...state,
                products: payload.products,
                updateSucceed: false,
                updateRecipeSucceed: false,
                succeed: false,
                error: false,
                loading: false
            };
        case GET_PRODUCT_BY_ID_SUCCESS:
            return {
                ...state,
                product: payload.product,
                updateSucceed: false,
                error: false,
                loading: false
            };
        case ADD_PRODUCT_SUCCESS:
            return {
                ...state,
                error: false,
                updateSucceed: false,
                succeed: true,
                loading: false
            };
        case UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                error: false,
                updateSucceed: true,
                loading: false
            };
        case GET_PRODUCT_TYPES_SUCCESS:
            return {
                ...state,
                productTypes: payload.productTypes,
                error: false,
                loading: false
            };
        case GET_PRODUCT_OPTIONS_SUCCESS:
            return {
                ...state,
                productOptions: payload.productOptions,
                error: false,
                loading: false
            };
        case GET_PRODUCTS_CATEGORIES_SUCCESS:
            return {
                ...state,
                productCategories: payload.productCategories,
                error: false,
                loading: false
            };
        case GET_PAINT_CATEGORIES_SUCCESS:
            return {
                ...state,
                paintCategories: payload.paintCategories,
                error: false,
                loading: false
            };
        case GET_PAINT_TYPES_SUCCESS:
            return {
                ...state,
                paintTypes: payload.paintTypes,
                error: false,
                loading: false
            };
        case GET_RECIPES_SUCCESS:
            return {
                ...state,
                recipes: payload.recipes,
                updateRecipeSucceed: false,
                succeed: true,
                error: false,
                loading: false
            };
        case GET_RECIPE_BY_ID_SUCCESS:
            return {
                ...state,
                recipe: payload.recipe,
                succeed: true,
                updateRecipeSucceed: false,
                error: false,
                loading: false
            };
        case ADD_UPDATE_RECIPE_DETAILS_SUCCESS:
            return {
                ...state,
                mapDetails: payload.mapDetails,
                updateRecipeSucceed: true,
                error: false,
                loading: false
            };
        case ADD_RECIPE_SUCCESS:
            return {
                ...state,
                recipe: payload.recipe,
                details: [],
                succeed: true,
                error: false,
                updateRecipeSucceed: false,
                loading: false
            };
        case ADD_RECIPE_FAIL:
        case GET_RECIPES_FAIL:
        case DELETE_RECIPE_FAIL:
        case GET_RECIPE_BY_ID_FAIL:
        case ADD_UPDATE_RECIPE_DETAILS_FAIL:
        case GET_PRODUCTS_FAIL:
        case GET_PRODUCT_TYPES_FAIL:
        case GET_PRODUCTS_CATEGORIES_FAIL:
        case GET_PAINT_CATEGORIES_FAIL:
        case GET_PRODUCTS_OPTIONS_FAIL:
        case GET_PAINT_TYPES_FAIL:
        case GET_PRODUCT_BY_ID_FAIL:
            return {
                ...state,
                error: true,
                message: payload.payload,
                loading: false
            };
        default:
            return state;
    }
}