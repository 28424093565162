import AuthService from "../services/auth.service";
import { API_ERROR_RESPONSE, API_SUCCESS_RESPONSE, LOGIN_FAIL, LOGIN_SUCCESS } from "./types";


export const apiError = (error) => {
    return {
        type: API_ERROR_RESPONSE,
        payload: {
            message: error.response.data,
            status: error.response.status,
            timestamp: Date.now()
        }
    };
};

export const resetApiError = () => {
    return {
        type: API_ERROR_RESPONSE,
        payload: null
    };
}

export const apiSuccess = () => {
    return {
        type: API_SUCCESS_RESPONSE,
        payload: {
            success: true,
            timestamp: Date.now()
        }
    }
}

export const resetApiSuccess = () => {
    return {
        type: API_SUCCESS_RESPONSE,
        payload: null
    }
}