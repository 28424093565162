import { Group } from "../models/group";
import {
    ADD_GROUP_FAIL,
    ADD_GROUP_SUCCESS, ADD_GROUPRIGHTS_FAIL, ADD_GROUPRIGHTS_SUCCESS,
    DELETE_GROUP_FAIL, DELETE_GROUP_SUCCESS,
    GET_GROUP_BY_ID_FAIL, GET_GROUP_BY_ID_SUCCESS, GET_GROUPS_FAIL,
    GET_GROUPS_SUCCESS, GET_RIGHTS_FOR_GROUP_FAIL, GET_RIGHTS_FOR_GROUP_SUCCESS,
    UPDATE_GROUP_FAIL, UPDATE_GROUP_SUCCESS
} from "../actions/types";

interface GroupState {
    groups: Group[],
    error: boolean,
    succeed: boolean
}

const initialState: GroupState = {
    groups: [],
    error: false,
    succeed: false
}

export default function groupReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_GROUPS_SUCCESS:
            return {
                ...state,
                error: false,
                succeed: false,
                groups: payload.groups,
            };
        case GET_GROUP_BY_ID_SUCCESS:
            return {
                ...state,
                group: payload.group,
                error: false
            };
        case ADD_GROUP_SUCCESS:
            return {
                ...state,
                error: false,
                succeed: true,
            };
        case UPDATE_GROUP_SUCCESS:
            return {
                ...state,
                error: false,
                succeed: true,
            };
        case DELETE_GROUP_SUCCESS:
            return {
                ...state,
                groups: state.groups.filter(d => d.id !== payload.id)
            };
        case GET_RIGHTS_FOR_GROUP_SUCCESS:
            return {
                ...state,
                rights: payload.rights,
                error: false,
                succeed: false
            }
        case ADD_GROUPRIGHTS_SUCCESS:
            return {
                ...state,
                error: false,
                succeed: true
            }
        case GET_GROUPS_FAIL:
        case GET_GROUP_BY_ID_FAIL:
        case ADD_GROUP_FAIL:
        case UPDATE_GROUP_FAIL:
        case DELETE_GROUP_FAIL:
        case GET_RIGHTS_FOR_GROUP_FAIL:
        case ADD_GROUPRIGHTS_FAIL:
            return {
                ...state,
                error: true,
                succeed: false,
                errorMsg: payload,
            };
        default:
            return state;
    }
}
