import {ANACOLOR_USER} from "../utils/constants";

export class PermissionHelper {
  static getCurrentPermissions(): string[] {
    const user = JSON.parse(localStorage.getItem(ANACOLOR_USER));
    if(user?.rights) {
      return user.rights;
    }
    return [];
  }

  static getCurrentGroups(): string[] {
    const user = JSON.parse(localStorage.getItem(ANACOLOR_USER));
    if(user?.groups) {
      return user.groups;
    }

    return [];
  }

  static currentUserInGroup(group: string | string[]): boolean {
    const groups = PermissionHelper.getCurrentGroups();

    if(!Array.isArray(group)) {
      group = [group];
    }

    for(let i = 0; i < groups.length; i++) {
      if (groups.includes(group[i])) {
        return true;
      }
    }

    return false;
  }

  static isCurrentUserAllowed(permission: string | string[]): boolean {
    const userPermissions = PermissionHelper.getCurrentPermissions();
    if(!Array.isArray(permission)) {
      permission = [permission];
    }
    let hasAccess = true;
    for(let i = 0; hasAccess && i < permission.length; i++) {
      hasAccess = userPermissions.includes(permission[i]);
    }

    return hasAccess;
  }
}
