import {
    ADD_QUOTE_SUCCESS, CONVERT_QUOTE_TO_PRODUCTION_ORDER_SUCCESS, DELETE_QUOTE_SUCCESS, GET_QUOTE_BY_ID_SUCCESS,
    GET_QUOTES_FAIL,
    GET_QUOTES_SUCCESS, UPDATE_QUOTE_SUCCESS,
} from "../actions/types";

const initialState = {quotes: [], quote: { currentQuote: null }};

export default function quoteReducer (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_QUOTES_SUCCESS:
            return {
                ...state,
                quotes: payload.quotes,
                error: false,
                succeed: false,
                quoteConverted: false,
                addedQuote: null
            };

        case GET_QUOTE_BY_ID_SUCCESS:
            return {
                ...state,
                quotes: payload.quotes,
                error: false,
                updateSucceed: false,
                quoteConverted: false,
                addedQuote: null,
                quote: {
                    ...state.quote,
                    currentQuote: payload.currentQuote
                }
            };
        case ADD_QUOTE_SUCCESS:
            return {
                ...state,
                error: false,
                updateSucceed: true,
                quoteConverted: false,
                addedQuote: payload
            };
        case UPDATE_QUOTE_SUCCESS:
            return {
                ...state,
                error: false,
                updateSucceed: true,
                quoteConverted: false,
                addedQuote: null,
            };
        case DELETE_QUOTE_SUCCESS:
            return {
                ...state,
                quotes: state.quotes.filter(d => d.id !== payload.id)
            };
        case GET_QUOTES_FAIL:
            return {
                ...state,
            };
        case CONVERT_QUOTE_TO_PRODUCTION_ORDER_SUCCESS:
            return {
              ...state,
                quoteConverted: true,
                succeed: false
            };
        default:
            return state;
    }
}