import {
  ADD_INVENTORY_FAIL,
  ADD_INVENTORY_SUCCESS,
  DELETE_INVENTORY_FAIL,
  DELETE_INVENTORY_SUCCESS,
  GET_INVENTORIES_FAIL,
  GET_INVENTORIES_SUCCESS,
  UPDATE_INVENTORY_FAIL,
  UPDATE_INVENTORY_SUCCESS,
} from "../actions/types";

const initialState = {inventories: [] };

export default function inventoryReducer (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_INVENTORIES_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: false,
        updateSucceed: false,
        inventories: payload.inventories,
      };
    
    case ADD_INVENTORY_SUCCESS:
      return {
        ...state,
        error: false,
        updateSucceed: false,
        succeed: true,
      };
    case UPDATE_INVENTORY_SUCCESS:
      return {
        ...state,
        error: false,
        updateSucceed: true,
      };
    case DELETE_INVENTORY_SUCCESS:
      return {
        ...state,
        inventories: state.inventories.filter(d => d.id !== payload.id)
      };
    case GET_INVENTORIES_FAIL:
    case ADD_INVENTORY_FAIL:
    case UPDATE_INVENTORY_FAIL:
    case DELETE_INVENTORY_FAIL:
      return {
        ...state,
        error: true,
        succeed: false,
        updateSucceed: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
}
