import {
  ADD_PRODUCTION_ORDER_QA_SUCCESS,
  ADD_PRODUCTION_SCHEDULING_FAIL,
  ADD_PRODUCTION_SCHEDULING_SUCCESS, DELETE_PRODUCTION_ORDER_FAIL, DELETE_PRODUCTION_ORDER_SUCCESS,
  DELETE_PRODUCTION_SCHEDULING_FAIL,
  DELETE_PRODUCTION_SCHEDULING_SUCCESS,
  GET_PRODUCTION_ORDER_BY_ID_FAIL,
  GET_PRODUCTION_ORDER_BY_ID_SUCCESS, GET_PRODUCTION_ORDER_DETAILS_FAIL, GET_PRODUCTION_ORDER_DETAILS_SUCCESS,
  GET_PRODUCTION_ORDERS_FAIL,
  GET_PRODUCTION_ORDERS_SUCCESS,
  GET_PRODUCTION_SCHEDULING_BY_ID_FAIL,
  GET_PRODUCTION_SCHEDULING_BY_ID_SUCCESS,
  GET_PRODUCTION_SCHEDULING_FAIL,
  GET_PRODUCTION_SCHEDULING_SUCCESS, UPDATE_PRODUCTION_ORDER_FAIL, UPDATE_PRODUCTION_ORDER_QA_SUCCESS,
  UPDATE_PRODUCTION_ORDER_SUCCESS,
  UPDATE_PRODUCTION_SCHEDULING_FAIL,
  UPDATE_PRODUCTION_SCHEDULING_SUCCESS,
} from "../actions/types";
import productionOrders from "../content/production/components/ProductionOrders";

const initialState = {
  capacity: {
    scheduling: []
  },
  order: {
    currentProductionOrder: {

    }
  },
  productionOrders: []
};

export default function productionReducer (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTION_SCHEDULING_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: false,
        updateSucceed: false,
        capacity: {
         scheduling: payload.scheduling
        },
      };

    case GET_PRODUCTION_SCHEDULING_BY_ID_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: false,
        updateSucceed: false,
        capacity: {
          currentScheduling: payload.currentCapacity
        },
      };
    
    case ADD_PRODUCTION_SCHEDULING_SUCCESS:
      return {
        ...state,
        error: false,
        updateSucceed: false,
        capacity: {
          succeed: true,
          addedCapacity: payload.capacity
        },
      };
    case UPDATE_PRODUCTION_SCHEDULING_SUCCESS:
      return {
        ...state,
        error: false,
        capacity: {
          updateSucceed: true,
          updatedCapacity: payload.capacity
        },
      };
    case DELETE_PRODUCTION_SCHEDULING_SUCCESS:
      return {
        ...state,
        capacity: {
          scheduling: state.capacity.scheduling.filter(d => d.id !== payload.id)
        }
      };
    case GET_PRODUCTION_ORDERS_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: true,
        updateSucceed: false,
        qaFormUpdated: false,
        productionOrders:payload.orders,
      };

    case GET_PRODUCTION_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: true,
        updateSucceed: false,
        qaFormUpdated: false,
        productionOrderDetails:payload.productionOrderDetails,
      };

    case GET_PRODUCTION_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: true,
        updateSucceed: false,
        qaFormUpdated: false,
        order: {
          ...state.order,
          currentProductionOrder: payload.currentOrder
        },
      };

    case UPDATE_PRODUCTION_ORDER_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: false,
        updateSucceed: true,
        order: {

          updatedOrder: payload.order
        },
      };

    case DELETE_PRODUCTION_ORDER_SUCCESS:
      return {
        ...state,
        productionOrders: state.productionOrders.filter(po => po.id !== payload.id),
        order: {
          ...state.order,
          deletedOrderId: payload.id
        }
      };

    case ADD_PRODUCTION_ORDER_QA_SUCCESS:
      return {
        ...state,
        error: false,
        qaFormUpdated: true,
      };

    case UPDATE_PRODUCTION_ORDER_QA_SUCCESS:
      return {
        ...state,
        error: false,
        qaFormUpdated: true,
      };

    case GET_PRODUCTION_SCHEDULING_FAIL:
    case GET_PRODUCTION_SCHEDULING_BY_ID_FAIL:
    case ADD_PRODUCTION_SCHEDULING_FAIL:
    case UPDATE_PRODUCTION_SCHEDULING_FAIL:
    case DELETE_PRODUCTION_SCHEDULING_FAIL:
    case GET_PRODUCTION_ORDERS_FAIL:
    case GET_PRODUCTION_ORDER_BY_ID_FAIL:
    case UPDATE_PRODUCTION_ORDER_FAIL:
    case DELETE_PRODUCTION_ORDER_FAIL:
    case GET_PRODUCTION_ORDER_DETAILS_FAIL:
      return {
        ...state,
        error: true,
        succeed: false,
        updateSucceed: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
}
