import {GET_PROFILE_FAIL, GET_PROFILE_SUCCESS, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT,} from "../actions/types";
import {ANACOLOR_TOKEN, ANACOLOR_USER} from "../utils/constants";

const user = JSON.parse(localStorage.getItem(ANACOLOR_USER));
const token = localStorage.getItem(ANACOLOR_TOKEN);

const initialState = user && token
    ? { isLoggedIn: true, user, error: null }
    : { isLoggedIn: false, user: null, error: null };

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                error: null,
            };
        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                error: payload,
            };
        case GET_PROFILE_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                error: null,
            };
        case GET_PROFILE_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                error: payload,
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        default:
            return state;
    }
}
