import {
    ADD_USER_FAIL, ADD_USER_SUCCESS,
    GET_USER_BY_ID_FAIL, GET_USER_BY_ID_SUCCESS,
    GET_USERS_FAIL,
    GET_USERS_SUCCESS, UPDATE_USER_FAIL, UPDATE_USER_SUCCESS
} from "../actions/types";
import {User} from "../models/user";
import {createSelector} from "reselect";

interface UserState {
    users: User[],
    updatedUser?: User,
    newUser?: User,
}

const initialState = {users: [], updatedUser: {} as User, newUser: {} as User };

export default function userReducer (state: UserState = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_USERS_SUCCESS:
            return {
                ...state,
                users: payload.users,
                updatedUser: {},
                newUser: {},
                user: {}
            };
        case GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                user: payload.user,
                updatedUser: {},
                newUser: {}
            };
        case ADD_USER_SUCCESS:
            return {
                ...state,
                newUser: payload.user
            };
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                updatedUser: payload.user
            };
        case GET_USERS_FAIL:
        case GET_USER_BY_ID_FAIL:
        case UPDATE_USER_FAIL:
        case ADD_USER_FAIL:
            return {
                ...state,
                message: payload.message,
            };
        default:
            return state;
    }
}

export const selectUsersOrderedByName = createSelector(
    (state: UserState) => state.users,
    (users) => users.sort((a,b) => a.fullname.localeCompare(b.fullname))
)
