
import { combineReducers } from "redux";
import auth from "./auth.reducer";
import businessReducer from "./business.reducer";
import commonsReducer from "./commons.reducer";
import communicationReducer from "./communication.reducer";
import eventReducer from "./event.reducer";
import quoteReducer from "./quote.reducer";
import contactReducer from "./contact.reducer";
import userReducer from "./user.reducer";
import productReducer from "./product.reducer";
import inventoryReducer from "./inventory.reducer";
import groupReducer from "./group.reducer";
import recipesReducer from "./recipes.reducer";
import opportunityReducer from "./opportunity.reducer";
import documentReducer from "./document.reducer";
import productionReducer from "./production.reducer";
import apiReducer from "./api.reducer";
import purchaseOrdersReducer from "./purchaseOrdersReducer";

export default combineReducers({
    auth,
    businesses: businessReducer,
    communications: communicationReducer,
    commons: commonsReducer,
    events: eventReducer,
    quotes: quoteReducer,
    contacts: contactReducer,
    users: userReducer,
    products: productReducer,
    inventories: inventoryReducer,
    //recipes: recipesReducer,
    groups: groupReducer,
    opportunities: opportunityReducer,
    documents: documentReducer,
    production: productionReducer,
    apiError: apiReducer,
    apiSuccess: apiReducer,
    purchaseOrders: purchaseOrdersReducer
});
