import React from 'react';
import {Permission} from "./types";
import usePermission from "./use-permission";
import { PermissionHelper } from "./permission.helper";

type Props = {
    to: Permission;
    fallback?: JSX.Element | string;
    loadingComponent?: JSX.Element | string;
};

type GroupProps = {
    groups: string | string[],
    fallback?: JSX.Element | string;
    loadingComponent?: JSX.Element | string;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
export const Restricted: React.FunctionComponent<Props> = ({to, fallback,loadingComponent, children}) => {

    // We "connect" to the provider thanks to the PermissionContext
    const allowed = usePermission(to);

    // If the user has that permission, render the children
    if(allowed){
        return <>{children}</>;
    }

    // Otherwise, render the fallback
    return <>{fallback}</>;
};

export const GroupRestricted: React.FunctionComponent<GroupProps> = ({groups, fallback, children}) => {
    const inGroup = PermissionHelper.currentUserInGroup(groups);

    if (inGroup) {
        return <>{children}</>
    }

    return <>{fallback}</>
}