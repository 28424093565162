import {useRoutes} from 'react-router-dom';
import routes from './router';
import mainRoutes from './router-main';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import {CssBaseline} from '@mui/material';
import PermissionProvider from "./permissions/permission.provider";
import {User} from "./models/user";
import {useDispatch, useSelector} from "react-redux";
import {getProfile, logout} from "./actions/auth.action";
import {useEffect} from "react";

interface StateProps {
    auth: {
        user: User,
    },
}

const App = () => {
  const dispatch = useDispatch();
  const content = useRoutes(routes.concat(mainRoutes));
  const {user} = useSelector((state: StateProps) => state.auth);


  useEffect(() => {
    if(user) {
      // Refresh user profile
      dispatch(getProfile())
    } else {
      dispatch(logout());
    }
  },[]);


  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
          <PermissionProvider permissions={user? user.rights : []}>
              <>
                  <CssBaseline />
                  {content}
              </>
          </PermissionProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
