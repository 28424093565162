import {
  ADD_CONTACT_FAIL,
  ADD_CONTACT_SUCCESS,
  DELETE_CONTACT_FAIL,
  DELETE_CONTACT_SUCCESS,
  GET_CONTACTS_BY_BUSINESS_ID_FAIL,
  GET_CONTACTS_BY_BUSINESS_ID_SUCCESS,
  GET_CONTACTS_SUCCESS,
  UPDATE_CONTACT_FAIL,
  UPDATE_CONTACT_SUCCESS
} from "../actions/types";
import {Contact} from "../models/contact";

interface ContactState {
  contacts: Contact[]
  newContact: Contact,
  updatedContact: Contact
}

const initialState = {contacts: [], newContact: {} as Contact, updatedContact: {} as Contact  };

export default function contactReducer (state: ContactState = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONTACTS_SUCCESS:
      return {
        ...state,
        contacts: payload.contacts,
      };
    case GET_CONTACTS_BY_BUSINESS_ID_SUCCESS:
      return {
        ...state,
        contacts: payload.contacts,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        newContact: payload.contact
      };
    case UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        updatedContact: payload.contact
      };
    case DELETE_CONTACT_SUCCESS:
      return {
        ...state,
        contacts: state.contacts.filter(d => d.id !== payload.id)
      };
    case GET_CONTACTS_BY_BUSINESS_ID_FAIL:
    case UPDATE_CONTACT_FAIL:
    case DELETE_CONTACT_FAIL:
    case ADD_CONTACT_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}
