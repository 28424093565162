import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Applications

const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));
const Transactions = Loader(lazy(() => import('src/content/applications/Transactions')));
const UserProfile = Loader(lazy(() => import('src/content/applications/Users/profile')));
const UserSettings = Loader(lazy(() => import('src/content/applications/Users/settings')));

// Components

const Buttons = Loader(lazy(() => import('src/content/templates/Components/Buttons')));
const Modals = Loader(lazy(() => import('src/content/templates/Components/Modals')));
const Accordions = Loader(lazy(() => import('src/content/templates/Components/Accordions')));
const Tabs = Loader(lazy(() => import('src/content/templates/Components/Tabs')));
const Badges = Loader(lazy(() => import('src/content/templates/Components/Badges')));
const Tooltips = Loader(lazy(() => import('src/content/templates/Components/Tooltips')));
const Avatars = Loader(lazy(() => import('src/content/templates/Components/Avatars')));
const Cards = Loader(lazy(() => import('src/content/templates/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/templates/Components/Forms')));

// Status

const Status404 = Loader(lazy(() => import('src/content/templates/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/templates/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/templates/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/templates/Status/Maintenance')));


const routes: RouteObject[] = [
  {
    path: 'management',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/management/transactions"
            replace
          />
        )
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: (
              <Navigate
                to="details"
                replace
              />
            )
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          },
        ]
      }
    ]
  },
  {
    path: 'components',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/components/buttons"
            replace
          />
        )
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      },
    ]
  }
];

export default routes;
