import {
  ADD_EVENTS_FAIL,
  ADD_EVENTS_SUCCESS,
  DELETE_EVENTS_FAIL,
  DELETE_EVENTS_SUCCESS,
  GET_EVENT_TYPES_SUCCESS,
  GET_EVENTS_FAIL,
  GET_EVENTS_SUCCESS,
  UPDATE_EVENTS_FAIL,
  UPDATE_EVENTS_SUCCESS,
} from "../actions/types";

const initialState = {events: [], newEvent: null, updatedEvent: null };

export default function eventReducer (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: false,
        updatedEvent: null,
        newEvent: null,
        events: payload.events,
      };
    case GET_EVENT_TYPES_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: false,
        updatedEvent: null,
        newEvent: null,
        eventTypes: payload.eventTypes,
      };
    case ADD_EVENTS_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: true,
        updatedEvent: null,
        newEvent: payload.event,
      };
    case UPDATE_EVENTS_SUCCESS:
      return {
        ...state,
        error: false,
        succeed: true,
        newEvent: null,
        updatedEvent: payload.event
      };
    case DELETE_EVENTS_SUCCESS:
      return {
        ...state,
        newEvent: null,
        updatedEvent: null,
        events: state.events.filter(d => d.id !== payload.id)
      };
    case GET_EVENTS_FAIL:
    case ADD_EVENTS_FAIL:
    case UPDATE_EVENTS_FAIL:
    case DELETE_EVENTS_FAIL:
      return {
        ...state,
        error: true,
        succeed: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
}
