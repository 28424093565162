import {FC, ReactNode} from 'react';
import {experimentalStyled} from '@mui/material/styles';
import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import {useSelector} from "react-redux";
import {Navigate} from "react-router";

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const Protected = (props) => {
  return (<>
    {props.isLoggedIn ? props.children : <Navigate to="/" />}
  </>);
}

interface StateProps {
  auth : {
    isLoggedIn: boolean
  }
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {

  const isLoggedIn = useSelector((state: StateProps) => state.auth.isLoggedIn);

  console.log("ENV = ", process.env);
  return (
    <Protected isLoggedIn={isLoggedIn}>
      { process.env.REACT_APP_IS_DEV === 'true' &&
          <div style={{width: "100%", color: "white", backgroundColor: "red", position: "relative", fontSize: "1.5em", fontWeight: "900", padding: "1em"}}>
            Ceci est la version en DÉVELOPPEMENT 20230308 de la plateforme.<br/>
            SVP utilisez <a href="https://anacolor.groupebaillargeon.com">https://anacolor.groupebaillargeon.com</a>
          </div>
      }
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </Protected>
  );
};

export default SidebarLayout;
