import {Box, List, ListItem, ListItemText, Menu, MenuItem,} from '@mui/material';
import React, {useRef, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {experimentalStyled} from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { Restricted } from "../../../../permissions/restricted";
import {getAllowedMenuItems, MenuItems} from "../../Sidebar/SidebarMenu/items";

const ListWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function MenuSection(menuItem: MenuItems) {
  const sectionRef = useRef<any>(null);
  const [isSectionOpen, setSectionOpen] = useState<boolean>(false);
  const handleSectionToggle = (state:boolean): void => {
    setSectionOpen(state);
  };

  return {
    item: (
      <ListItem
        key={menuItem.heading}
        classes={{ root: 'MuiListItem-indicators' }}
        button
        ref={sectionRef}
        onClick={() => handleSectionToggle(true)}
      >
        <ListItemText
          primaryTypographyProps={{ noWrap: true }}
          primary={
            <Box display="flex" alignItems="center">
              { menuItem.heading }
              <Box display="flex" alignItems="center" pl={0.3}>
                <ExpandMoreTwoToneIcon fontSize="small" />
              </Box>
            </Box>
          }
        />
      </ListItem>
    ),
    submenu: (
      <Menu anchorEl={sectionRef.current} onClose={() => handleSectionToggle(false)} open={isSectionOpen} onClick={() => handleSectionToggle(false)} key={menuItem.heading}>
        {menuItem.items.map(item => (
          <Restricted to={item.permission} key={item.name}>
            <MenuItem sx={{ px: 3 }} component={NavLink} to={item.link}>
              {item.name}
            </MenuItem>
          </Restricted>
        ))}
      </Menu>
    )
  };
}

function HeaderMenu() {
  const menuSections = getAllowedMenuItems().map(MenuSection);

  return (
    <>
      <ListWrapper>
        <List disablePadding component={Box} display="flex">
          {menuSections.map(m => m.item)}
        </List>
      </ListWrapper>
      {menuSections.map(m => m.submenu)}
    </>
  );
}

export default HeaderMenu;
