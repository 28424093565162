import {
    ADD_PURCHASE_ORDERS_FAIL,
    ADD_PURCHASE_ORDERS_SUCCESS,
    GET_PURCHASE_ORDERS_FAIL,
    GET_PURCHASE_ORDERS_SUCCESS, UPDATE_PURCHASE_ORDERS_FAIL, UPDATE_PURCHASE_ORDERS_SUCCESS
} from "../actions/types";


const initialState = {
    purchaseOrders: [],
    purchaseOrderSaved: false,
    purchaseOrderUpdated: false
}
export default function purchaseOrdersReducer(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                error: false,
                purchaseOrders: payload.purchaseOrders,
                purchaseOrderSaved: false,
                purchaseOrderUpdated: false

            }
        case ADD_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                error: false,
                purchaseOrder: payload.purchaseOrder,
                purchaseOrderSaved: true,
                purchaseOrderUpdated: false
            }
        case UPDATE_PURCHASE_ORDERS_SUCCESS:
            return {
                ...state,
                error: false,
                purchaseOrder: payload.purchaseOrder,
                purchaseOrderUpdated: true,
                purchaseOrderSaved: false
            }
        case GET_PURCHASE_ORDERS_FAIL:
        case ADD_PURCHASE_ORDERS_FAIL:
        case UPDATE_PURCHASE_ORDERS_FAIL:
            return {
                ...state,
                error: true,
                errorMsg: payload,
                purchaseOrderSaved: false,
                purchaseOrderUpdated: false

            }
        default:
            return state;
    }
}