import {
  ADD_BUSINESSES_FAIL,
  ADD_BUSINESSES_SUCCESS,
  DELETE_BUSINESS_FAIL,
  DELETE_BUSINESS_SUCCESS,
  GET_BUSINESS_BY_ID_FAIL,
  GET_BUSINESS_BY_ID_SUCCESS,
  GET_BUSINESSES_FAIL,
  GET_BUSINESSES_SUCCESS,
  GET_PAYMENT_TERMS_FAIL,
  GET_PAYMENT_TERMS_SUCCESS,
  UPDATE_BUSINESS_FAIL,
  UPDATE_BUSINESS_SUCCESS
} from "../actions/types";
import {Business} from "../models/business";
import {createSelector} from "reselect";

interface BusinessState {
  businesses?: Business[],
  newBusiness?: Business,
  updatedBusiness?: Business
}

const initialState = {
  businesses: [],
  newBusiness: {} as Business,
  updatedBusiness: {} as Business
};

export default function businessReducer (state: BusinessState = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BUSINESSES_SUCCESS:
      return {
        ...state,
        businesses: payload.businesses,
        newBusiness : {},
        updatedBusiness : {}
      };
    case GET_BUSINESS_BY_ID_SUCCESS:
      return {
        ...state,
        business: payload.business,
        newBusiness : {},
        updatedBusiness : {}
      };
    case ADD_BUSINESSES_SUCCESS:
      return {
        ...state,
        newBusiness: payload.business
      };
    case UPDATE_BUSINESS_SUCCESS:
      return {
        ...state,
        updatedBusiness: payload.business
      };
    case DELETE_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: state.businesses.filter(d => d.id !== payload.id)
      };
    case GET_PAYMENT_TERMS_SUCCESS:
      return {
        ...state,
        paymentTerms: payload.paymentTerms,
      };
    case GET_BUSINESSES_FAIL:
    case ADD_BUSINESSES_FAIL:
    case UPDATE_BUSINESS_FAIL:
    case DELETE_BUSINESS_FAIL:
    case GET_BUSINESS_BY_ID_FAIL:
    case GET_PAYMENT_TERMS_FAIL:

      return {
        ...state,
      };
    default:
      return state;
  }
}


export const selectBusinessesOfType = createSelector(
  (state: BusinessState) => state.businesses,
  (_, typeId) => typeId,
  (businesses, typeId) => businesses.filter(b => b.businesstypeid === typeId)
)

export const selectBusinessesOrderedByName = createSelector(
    (state: BusinessState) => state.businesses,
    (businesses) => businesses.sort((a,b) => a.name.localeCompare(b.name))
)

export const selectBusinessesOfTypeOrderedByName = createSelector(
  [selectBusinessesOfType],
  (businesses) => businesses.sort((a,b) => a.name.localeCompare(b.name))
)

