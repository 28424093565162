import { API_ERROR_RESPONSE, API_SUCCESS_RESPONSE } from "../actions/types";
import * as path from "path";


const initialState = { apiError: null, apiSuccess: null }

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case API_ERROR_RESPONSE:
            return {
                ...state,
                apiError: payload
            };

        case API_SUCCESS_RESPONSE:
            return {
                ...state,
                apiSuccess: payload
            }

        default:
            return state;
    }
}