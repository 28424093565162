
import React from 'react';
import {Permission} from "./types";
import PermissionContext from "./permission-context";

type Props = {
    permissions: Permission[],
}

const PermissionProvider: React.FunctionComponent<Props> = ({permissions, children}) => {

    const isAllowedTo = (permission: Permission) => permissions?.includes(permission) ?? true;
    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;