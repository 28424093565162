import {ReactNode} from 'react';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from "@mui/icons-material/Person";
import ScienceIcon from '@mui/icons-material/Science';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { GroupWork, ShoppingCart } from "@mui/icons-material";
import ColorLensIcon from '@mui/icons-material/ColorLens';
import {Permission, Permissions} from "../../../../permissions/types";
import {PermissionHelper} from "../../../../permissions/permission.helper";

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
  permission?: Permission;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

export const getAllowedMenuItems = () => menuItems.filter(section =>
  section.items.reduce(
    (access, menuItem) => access || PermissionHelper.isCurrentUserAllowed(menuItem.permission),
    false
  )
);

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboards',
    items: [
      {
        name: 'Aperçu',
        link: '/dashboard',
        icon: BrightnessLowTwoToneIcon
      }
    ]
  },
  {
    heading: 'Admin',
    items: [
      {
        name: 'Utilisateurs',
        link: '/admin/users',
        icon: SupervisedUserCircleIcon,
        permission: Permissions.USERS_READ
      },
      {
        name: 'Groupes',
        link: '/admin/groups',
        icon: GroupWork,
        permission: Permissions.USERS_WRITE
      }
    ]
  },
  {
    heading: 'CRM',
    items: [
      {
        name: 'Entreprises',
        icon: GroupsIcon,
        link: '/crm/businesses',
        permission: Permissions.BUSINESSES_READ
      },
      {
        name: 'Contacts',
        icon: PersonIcon,
        link: '/crm/contacts',
        permission: Permissions.CONTACTS_READ
      }
    ]
  },
  {
    heading: 'Ventes',
    items: [
      {
        name: 'Opportunités',
        icon: MmsTwoToneIcon,
        link: '/sales/opportunities',
        permission: Permissions.OPPORTUNITIES_READ
      },
      {
        name: 'Soumissions',
        icon: TableChartTwoToneIcon,
        link: '/sales/quotes',
        permission: Permissions.QUOTES_READ
      },
      {
        name: 'Communications',
        icon: MmsTwoToneIcon,
        link: '/sales/communications',
        permission: Permissions.COMMUNICATIONS_READ
      },
      {
        name: 'Événements',
        icon: MmsTwoToneIcon,
        link: '/sales/events',
        permission: Permissions.EVENTS_READ
      },
    ]
  },
  {
    heading: 'Achats',
    items: [
      {
        name: 'Peintures',
        icon: FormatPaintIcon,
        link: '/purchases/products/paints',
        permission: Permissions.PRODUCTS_READ
      },
      {
        name: 'Laboratoire',
        icon: ScienceIcon,
        link: '/purchases/products/labo',
        permission: Permissions.SUPPLIERS_READ
      },
      {
        name: 'Fournisseurs',
        icon: GroupsIcon,
        link: '/purchases/suppliers',
        permission: Permissions.SUPPLIERS_READ
      },
      {
        name: 'Inventaires',
        icon: TableChartTwoToneIcon,
        link: '/purchases/inventories',
        permission: Permissions.INVENTORIES_READ
      },
      {
        name: 'Commandes',
        icon: ShoppingCart,
        link: '/purchases/purchaseorders',
        permission: Permissions.INVENTORIES_READ
      }
    ]
  },
  {
    heading: 'Production',
    items: [
      {
        name: 'Vue Globale',
        icon: ColorLensIcon,
        link: '/production/order/overview',
        permission: Permissions.PRODUCTION_READ
      },
      {
        name: 'Capacité',
        icon: TableChartTwoToneIcon,
        link: '/production/capacity',
        permission: Permissions.PRODUCTION_READ
      },
    ]
  },
    /*
  {
    heading: 'Extra Pages',
    detail: [
      {
        name: 'Status',
        icon: VerifiedUserTwoToneIcon,
        link: '/status',
        detail: [
          {
            name: 'Error 404',
            link: '/status/404'
          },
          {
            name: 'Error 500',
            link: '/status/500'
          },
          {
            name: 'Maintenance',
            link: '/status/maintenance'
          },
          {
            name: 'Coming Soon',
            link: '/status/coming-soon'
          }
        ]
      }
    ]
  }

     */
];

export default menuItems;
