import {useRef, useState} from 'react';

import {Avatar, Box, Button, Divider, Hidden, lighten, Popover, Typography} from '@mui/material';
import {experimentalStyled} from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../../../actions/auth.action";
import {useNavigate} from "react-router";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);
interface StateProps {
  auth : {
    user: {
      fullname: string
    }
  }
}
function HeaderUserbox() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: StateProps) => state.auth.user);
  const avatar = '/static/images/logo/anacolor_square.png';
  // const user =
  // {
  //   name: 'Caroline Clément',
  //   avatar: '/static/images/avatars/1.jpg',
  //   jobtitle: 'Chargée de projet'
  // };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.fullname} src={avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.fullname}</UserBoxLabel>
            {/*<UserBoxDescription variant="body2">*/}
            {/*  {user.jobtitle}*/}
            {/*</UserBoxDescription>*/}
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.fullname} src={avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.fullname}</UserBoxLabel>
            {/*<UserBoxDescription variant="body2">*/}
            {/*  {user.jobtitle}*/}
            {/*</UserBoxDescription>*/}
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        {/*<List sx={{ p: 1 }} component="nav">*/}
        {/*  <ListItem button to="/management/profile/details" component={NavLink}>*/}
        {/*    <AccountBoxTwoToneIcon fontSize="small" />*/}
        {/*    <ListItemText primary="My Profile" />*/}
        {/*  </ListItem>*/}
        {/*  <ListItem*/}
        {/*    button*/}
        {/*    to="/dashboards/messenger"*/}
        {/*    component={NavLink}*/}
        {/*  >*/}
        {/*    <InboxTwoToneIcon fontSize="small" />*/}
        {/*    <ListItemText primary="Messenger" />*/}
        {/*  </ListItem>*/}
        {/*  <ListItem*/}
        {/*    button*/}
        {/*    to="/management/profile/settings"*/}
        {/*    component={NavLink}*/}
        {/*  >*/}
        {/*    <AccountTreeTwoToneIcon fontSize="small" />*/}
        {/*    <ListItemText primary="Account Settings" />*/}
        {/*  </ListItem>*/}
        {/*</List>*/}
        {/*<Divider />*/}
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout} >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Se déconnecter
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
