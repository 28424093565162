import {GET_PROFILE_FAIL, GET_PROFILE_SUCCESS, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT} from "./types";
import AuthService from "../services/auth.service";
import {ANACOLOR_TOKEN, ANACOLOR_USER} from "../utils/constants";

export const login = (username, password) => (dispatch) => {
    return AuthService.login(username, password).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });
            return Promise.resolve();
        },
        (error) => {
            const message = error.response.data ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
                payload: message
            });

            return Promise.reject();
        }
    );
};

export const getProfile = () => (dispatch) => {

    return AuthService.getProfile().then(
        (data) => {
            dispatch({
                type: GET_PROFILE_SUCCESS,
                payload: { user: data },
            });
            return Promise.resolve(data);
        },
        (error) => {
            const message = error.response.data ||
                error.message ||
                error.toString();

            dispatch({
                type: GET_PROFILE_FAIL,
                payload: message
            });

            return Promise.reject();
        }
    );
}

export const logout = () => (dispatch) => {
    localStorage.removeItem(ANACOLOR_USER);
    localStorage.removeItem(ANACOLOR_TOKEN);
    dispatch({type: LOGOUT});
}
