import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Authentication

const Login = Loader(lazy(() => import('src/content/auth/Login')));

// Administration
const Users = Loader(lazy(() => import('src/content/administration/Users')));
const UpdateUser = Loader(lazy(() => import('src/content/administration/UpdateUser')));
const AddUser = Loader(lazy(() => import('src/content/administration/AddUser')));
const Groups = Loader(lazy(() => import('src/content/administration/Groups')));
const UpdateGroup = Loader(lazy(() => import('src/content/administration/UpdateGroup')));
const AddGroup = Loader(lazy(() => import('src/content/administration/AddGroup')));

// CRM

const AddBusiness = Loader(lazy(() => import('src/content/crm/AddBusiness')));
const UpdateBusiness = Loader(lazy(() => import('src/content/crm/UpdateBusiness')));
const AddContact = Loader(lazy(() => import('src/content/crm/AddContact')));
const UpdateContact = Loader(lazy(() => import('src/content/crm/UpdateContact')));
const Contacts = Loader(lazy(() => import('src/content/crm/Contacts')));
const Customers = Loader(lazy(() => import('src/content/crm/Customers')));
const Suppliers = Loader(lazy(() => import('src/content/purchases/Suppliers')));

// SALES
const Communications = Loader(lazy(() => import('src/content/sales/Communications')));
const AddCommunication = Loader(lazy(() => import('src/content/sales/AddCommunication')));
const UpdateCommunication = Loader(lazy(() => import('src/content/sales/UpdateCommunication')));
const Events = Loader(lazy(() => import('src/content/sales/Events')));
const AddEvent = Loader(lazy(() => import('src/content/sales/AddEvent')));
const UpdateEvent = Loader(lazy(() => import('src/content/sales/UpdateEvent')));
const AddQuote = Loader(lazy(() => import('src/content/sales/AddQuote')));
const Quotes = Loader(lazy(() => import('src/content/sales/Quotes')));
const UpdateQuote = Loader(lazy(() => import('src/content/sales/UpdateQuote')));
const AddOpportunity = Loader(lazy(() => import('src/content/sales/AddOpportunity')));
const Opportunities = Loader(lazy(() => import('src/content/sales/Opportunities')));
const UpdateOpportunity = Loader(lazy(() => import('src/content/sales/UpdateOpportunity')));

// PURCHASES
const Paints = Loader(lazy(() => import('src/content/purchases/products/Paints')));
const AddPaintProduct = Loader(lazy(() => import('src/content/purchases/AddPaintProduct')));
const UpdatePaintProduct = Loader(lazy(() => import('src/content/purchases/UpdatePaintProduct')));
const Labs = Loader(lazy(() => import('src/content/purchases/products/Labs')));
const AddLaboProduct = Loader(lazy(() => import('src/content/purchases/AddLaboProduct')));
const UpdateLaboProduct = Loader(lazy(() => import('src/content/purchases/UpdateLaboProduct')));
const Recipes = Loader(lazy(() => import('src/content/purchases/Recipes')));
const UpdateRecipe = Loader(lazy(() => import('src/content/purchases/UpdateRecipe')));
const AddRecipe = Loader(lazy(() => import('src/content/purchases/AddRecipe')));


const Inventories = Loader(lazy(() => import('src/content/purchases/Inventories')));
const AddInventory = Loader(lazy(() => import('src/content/purchases/AddInventory')));
const UpdateInventory = Loader(lazy(() => import('src/content/purchases/UpdateInventory')));

const PurchaseOrders = Loader(lazy(() => import('src/content/purchases/PurchaseOrders')));
const AddPurchaseOrder = Loader(lazy(() => import('src/content/purchases/AddPurchaseOrder')));
const UpdatePurchaseOrder = Loader(lazy(() => import('src/content/purchases/UpdatePurchaseOrder')));

// PRODUCTIONS
const ProductionPlaning = Loader(lazy(() => import('src/content/production/ProductionPlaning')));
const CapacityPlaning = Loader(lazy(() => import('src/content/production/CapacityPlaning')));
const ProductionOverview = Loader(lazy(() => import('src/content/production/Overview')));
const UpdateProductionOrder = Loader(lazy(() => import('src/content/production/UpdateProductionOrder')));
const AddProductionOrder = Loader(lazy(() => import('src/content/production/AddProductionOrder')));
const AddProductionOrderQA = Loader(lazy(() => import('src/content/production/AddProductionOrderQA')));

const routes: RouteObject[] = [
  {
    path: '*',
    element: <Login />,
    children: [
      {
        path: '*',
        element: (
            <Navigate
                to="/auth/login"
                replace
            />
        )
      }
    ]
  },
  {
    path: '/auth/login',
    element: <Login />
  },
  {
    path: '/dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Overview />
      }
    ]
  },
  {
    path: 'admin',
    element: (
        <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
            <Navigate
                to="/admin/users"
                replace
            />
        )
      },
      {
        path: 'users',
        children: [
          {
            path:'',
            element: <Users />
          },
          {
            path: 'edit',
            element: <UpdateUser title='Mise à jour Utilisateur' />
          },
          {
            path: 'new',
            element: <AddUser title='Nouvel Utilisateur' />
          },
        ]
      },
      {
        path: 'groups',
        children: [
          {
            path:'',
            element: <Groups />
          },
          {
            path: 'edit',
            element: <UpdateGroup title='Mise à jour Utilisateur' />
          },
          {
            path: 'new',
            element: <AddGroup title='Nouvel Utilisateur' />
          },
        ]
      },
    ]
  },
  {
    path: 'crm',
    element: (
      <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/crm/businesses"
            replace
          />
        )
      },
      {
        path: 'businesses',
        children: [
          {
            path:'',
            element: <Customers />
          },
          {
            path: 'edit',
            element: <UpdateBusiness title='Mise à jour Entreprise' />
          },
          {
            path: 'new',
            element: <AddBusiness title='Nouvelle Entreprise' />
          },
          {
            path: 'contacts',
            children: [
              {
                path:'',
                element: <Contacts />
              },
              {
                path: 'edit',
                element: <UpdateContact />
              },
            ]
          }
        ]
      },
      {
        path: 'contacts',
        children: [
          {
            path: '',
            element: <Contacts />
          },
          {
            path: 'new',
            element: <AddContact showInternalButtons={true} />
          },
          {
            path: 'edit',
            element: <UpdateContact />
          },
        ]
      },
    ]
  },
  {
    path: 'sales',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: (
            <Navigate
                to="/sales/quotes"
                replace
            />
        )
      },
      {
        path: 'quotes',
        children: [
          {
            path: '',
            element: <Quotes />
          },
          {
            path: 'new',
            element: <AddQuote />
          },
          {
            path: 'edit',
            element: <UpdateQuote />
          }
        ]
      },
      {
        path: 'communications',
        children: [
          {
            path: '',
            element: <Communications />
          },
          {
            path: 'new',
            element: <AddCommunication />
          },
          {
            path: 'edit',
            element: <UpdateCommunication />
          },
        ]
      },
      {
        path: 'opportunities',
        children: [
          {
            path: '',
            element: <Opportunities />
          },
          {
            path: 'new',
            element: <AddOpportunity />
          },
          {
            path: 'edit',
            element: <UpdateOpportunity />
          },
        ]
      },
      {
        path: 'events',
        children: [
          {
            path: '',
            element: <Events />
          },
          {
            path: 'new',
            element: <AddEvent />
          },
          {
            path: 'edit',
            element: <UpdateEvent />
          },
        ]
      },
    ]
  },
  {
    path: 'purchases',
    element: (
        <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
            <Navigate
                to="/purchases/products"
                replace
            />
        )
      },
      {
        path: 'products/paints',
        children: [
          {
            path: '',
            element: <Paints />
          },
          {
            path: 'new',
            element: <AddPaintProduct />
          },
          {
            path: 'edit',
            element: <UpdatePaintProduct />
          },
        ]
      },
      {
        path: 'products/labo',
        children: [
          {
            path: '',
            element: <Labs />
          },
          {
            path: 'new',
            element: <AddLaboProduct />
          },
          {
            path: 'edit',
            element: <UpdateLaboProduct />
          },
        ]
      },
      {
        path: 'suppliers',
        children: [
          {
            path:'',
            element: <Suppliers />
          },
          {
            path: 'edit',
            element: <UpdateBusiness title='Mise à jour Fournisseur' />
          },
          {
            path: 'new',
            element: <AddBusiness title='Nouveau Fournisseur'/>
          },
        ]
      },
      {
        path: 'inventories',
        children: [
          {
            path: '',
            element: <Inventories />
          },
          {
            path: 'new',
            element: <AddInventory />
          },
          {
            path: 'edit',
            element: <UpdateInventory />
          },
        ]
      },
      {
        path: 'purchaseorders',
        children: [
          {
            path: '',
            element: <PurchaseOrders />
          },
          {
            path: 'new',
            element: <AddPurchaseOrder />
          },
          {
            path: 'edit',
            element: <UpdatePurchaseOrder />
          },
        ]
      },

      {
        path: 'recipes',
        children: [
          {
            path: 'edit',
            element: <UpdateRecipe title='Mise à jour Recette' />
          },
          {
            path: 'new',
            element: <AddRecipe title='Nouvelle Recette'/>
          },
        ]
      }
    ]
  },
  {
    path: 'production',
    element: (
        <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: (
            <Navigate
                to="/production/planing"
                replace
            />
        )
      },
      {
        path: 'order',
        children: [
          {
            path: 'overview',
            element: <ProductionOverview />
          },
          {
            path: 'edit',
            element: <UpdateProductionOrder />
          },
          {
            path: 'new',
            element: <AddProductionOrder />
          },
        ]
      },
      {
        path: 'qa',
        children: [
          {
            path: 'new',
            element: <AddProductionOrderQA />
          },
        ]
      },
      {
        path: 'planing',
        children: [
          {
            path: '',
            element: <ProductionPlaning />
          },
        ]
      },
      {
        path: 'capacity',
        children: [
          {
            path: '',
            element: <CapacityPlaning />
          },
        ]
      },
    ]
  },
];

export default routes;
