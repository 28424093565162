import {
    ADD_DOCUMENT_FAIL,
    ADD_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_FAIL,
    DELETE_DOCUMENT_SUCCESS,
    GET_DOCUMENTS_SUCCESS,
    UPDATE_DOCUMENT_FAIL,
    UPDATE_DOCUMENT_SUCCESS, UPLOAD_DOCUMENT_FAIL, UPLOAD_DOCUMENT_SUCCESS
} from "../actions/types";
import {Document} from "../models/document";

interface DocumentState {
    documents: Document[]
    newDocument: Document,
    updatedDocument: Document
}

const initialState = {documents: [], newDocument: {} as Document, updatedDocument: {} as Document  };

export default function documentReducer (state: DocumentState = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: payload.documents,
            };
        case ADD_DOCUMENT_SUCCESS:
            return {
                ...state,
                newDocument: payload.document
            };
        case UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                uploadedDocument: payload.document
            };
        case UPDATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                updatedDocument: payload.document
            };
        case DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                documents: state.documents.filter(d => d.id !== payload.id)
            };
        case UPLOAD_DOCUMENT_FAIL:
            return {
                ...state,
                uploadError: payload
            };
        case UPDATE_DOCUMENT_FAIL:
        case DELETE_DOCUMENT_FAIL:
        case ADD_DOCUMENT_FAIL:
            return {
                ...state,
            };
        default:
            return state;
    }
}
